export const Elements = [
  {
    label: "Survey elements",
    elements: [
      {
        id: "mc_elements",
        label: "Multiple Choice",
        icon: "/icons-survey-element-dropdown.svg",
      },
      {
        id: "mc_elements",
        label: "Multiple Images",
        icon: "/icons-survey-element-multiple-image-copy.svg",
        settings: { view: "multiple_image" },
      },
      {
        id: "oa_elements",
        label: "Open Answer",
        icon: "/icons-survey-element-text-form.svg",
      },
      {
        id: "mc_elements",
        label: "Dropdown",
        icon: "/icons-survey-element-multiple-choice.svg",
        settings: { view: "dropdown" },
      },
      {
        id: "scl_elements",
        label: "Scale",
        icon: "/scale-icon.svg",
      },
    ],
  },
  {
    label: "Instructions elements",
    elements: [
      {
        id: "txt_elements",
        label: "Text",
        icon: "/icons-text-element.svg",
      },
      {
        id: "img_elements",
        label: "Image",
        icon: "/icons-image-element.svg",
      },
      {
        id: "vid_elements",
        label: "Video",
        icon: "/icons-video-element.svg",
      },
    ],
  },
  {
    label: "Offer elements",
    elements: [
      {
        id: "opo_elements",
        label: "Open Price",
        icon: "/icons-offer-element.svg",
      },
      {
        id: "fpo_elements",
        label: "Fixed Price",
        icon: "/icons-fixed-offer-element.svg",
      },
    ],
  },
  {
    label: "Other elements",
    elements: [
      {
        id: "c_elements",
        label: "Container",
        icon: "/icons-group-element.svg",
      },
      ,
      {
        id: "checkout_elements",
        label: "Checkout",
        icon: "/checkout.svg",
      },
    ],
  },
];
